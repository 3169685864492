import axios from 'axios'
import store from '@/store'
import i18n from '@/lang'
import { Toast } from 'vant'
import { removeToken } from '@/utils/auth'
import router from '@/router'
import { LOGIN } from '@/config/global_data'
// import { LANGUAGE_TYPE } from '@/config/global_data'

window.global = {
  baseUrl: 'https://api.loe.xt528.com',
  baseWsUrl: 'wss://api.loe.xt528.com/ws'
}

const la = navigator.language.toLowerCase();
let language = 'en';
if (la.indexOf('zh') != -1) language = 'zh';
const URL = window.global;

let BASE_URL = URL[Object.keys(URL)[0]];
// console.log(BASE_URL)
for (let key in URL) {
  if (key == language) BASE_URL = URL[key];
}

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000 // request timeout
})
// request拦截器
instance.interceptors.request.use(
  config => {
    // Toast.loading({
    //   message: `${i18n.t('加载中')}...`,
    // })
    // console.log(config)
    // const { token, language } = store.getters
    // if (token) {
    //   config.headers['X-Api-Key'] = token
    //   LOGIN.action = 'login'
    //   LOGIN.access_token = token
    //   config.headers['Authorization'] = "Bearer " + token
    // }
    let token = ''

    //判断token
    if (window.localStorage.getItem("user")) {
      // console.log(JSON.parse(window.localStorage.getItem("user")))
      token = JSON.parse(window.localStorage.getItem("user")).token;
    }
    config.headers['device'] = 'pc'
    config.headers['Authorization'] = 'Bearer ' + token
    // config.headers['lang'] = language || LANGUAGE_TYPE[0].value
    config.headers['Accept-Language'] = language || 'en'
    return config
  },
  error => {
    Toast.clear()
    console.error(error) // for debug
    return Promise.reject(error)
  }
)

// respone拦截器
instance.interceptors.response.use(
  response => {
    // Toast.clear()
    const res = response.data
    // console.log(res, response)
    //特殊接口直接return
    if (response.config.url == '/captcha/api/default' || response.config.url == '/api/logout' || response.config.url == '/api/identity-auths') {
      return res
    }
    if (res.code !== 200) {
      // : Illegal token; : Other clients logged in; 401: Token expired;
      if (res.code === 401) {
        console.log(1111111111)

        //退出登录删除tokn
        window.localStorage.removeItem('user');
        // removeToken()
        Toast(`${i18n.t('您还未登录，请先登录')}` || `${i18n.t('登录')} ${i18n.t('过期')}`)
        // store.dispatch('resetInfo').then(() => {
        //   // location.reload()
        //   router.push('/login')
        // })
        router.push('/login')
        return Promise.reject(res)
      } else {
        Toast({
          message: res.message || 'Error',
          duration: 3 * 1000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      // 携带response响应头版本信息
      // const { headers } = response
      // res['kv'] = headers.kv || ''
      // store.dispatch('app/setServiceTime', headers['service-time'])
      return res
    }
  },
  error => {
    //退出登录删除tokn
    // window.localStorage.removeItem('user');
    // Toast(`${i18n.t('您还未登录，请先登录')}`)
    // router.push('/login')

    // Toast.clear()
    Toast({
      message: error.message,
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

// GET请求
export const requestGet = (url, params) => instance.get(url, { params })
// DELETE请求
export const requestDelete = (url, params) => instance.delete(url, { params })
// POST请求
export const requestPost = (url, params) => instance.post(url, params)
// PUT请求
export const requestPut = (url, params) => instance.put(url, params)

export default instance
