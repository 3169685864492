import { requestGet } from '@/api/axios'

export const API_VERSION = 'v1'

export const getCommonData = (params) => requestGet(`/api/${API_VERSION}` + '/common-dic/index', params)

// export const HOME_VIDEO_URL = data.introduction_page + '/tronapp/tut.MP4'

// export const HOME_SERVICE_URL = data.kefu

// export const HOME_SERVICE_GROUP_URL = data.group

// export const APP_DOWNLOAD_URL = data.app_landing
export const THEME_COLOR = '#136DF9'

export const LANGUAGE_TYPE = [
  // { label: '简体中文', value: 'zh-cn' },
  { label: 'English', value: 'en', },
  { label: '繁體中文', value: 'zh-hk', },
  { label: '日本', value: 'ja', },
  { label: 'عربي', value: 'ar', },
  { label: 'Français', value: 'fr', },
  { label: 'Deutsch', value: 'de', },
  { label: 'español', value: 'es', },
  { label: 'Italiano', value: 'it', },
  { label: 'Português', value: 'pt', },
  { label: 'Indonesia', value: 'id', },
  { label: 'ไทย', value: 'th', },
  { label: 'Türk', value: 'tr', },
  { label: '한국인', value: 'kr', },
  { label: 'Tiếng Việt', value: 'vi', },
  { label: 'Pусский язык', value: 'ru', },
]

export const BILL_TYPE = {
  '1': '在线充值',
  '2': '平台赠送',
  '3': '推广收益',
  '4': '机器消耗',
  '6': '期权支出',
  '7': '期权收入',
  '8': '用户提现',
  '10': '资金划转',
  '11': '购买理财',
  '12': '理财赎回',
  '13': '游戏消费',
  '14': '游戏收益',
  '15': '资金划转出',
  '17': '提现手续费',
  '18': '冻结资金',
  '19': '解冻资金',
  '20': '闪兑支出',
  '21': '闪兑收入',
  '22': '闪兑手续费',
  '23': '合约支出',
  '24': '合约收入',
  '25': '合约卖出手续费',
  '26': '合约买入手续费',
  '27': '在线充值',
  '28': '系统减款',
  '29': '任务奖励',
  '30': '合约资金费率',
  '31': '佣金分成',
  '32': '法币充值',
  '33': '法币提现',
  '34': '在线充值',
  '35': '足球收入',
  '36': '足球支出',
  '42': 'VIP奖励',
  '43': '平台出资',
  '44': '平台出资收回',
  '45': '投资大师奖励',
  '46': '彩票支出',
  '47': '彩票收入',
  '48': '外汇',
  '49': '在线充值'
}

export const PARAMETER = {
  class: 'index',
  action: 'joinRoom',
  room: '',
  access_token: ''
}
export const LOGIN = {
  class: 'index',
  action: 'login',
  room: '',
  access_token: ''
}
